
//COLOR//


.blackColor {
  color: #1d1d1d;
}
$mainColor: #7e1616;

//Flex//
.disRow {
  display: flex;
  flex-direction: row;
}
.disColumn {
  display: flex;
  flex-direction: column;
}
.disRowCenter {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

//IMAGE//
.thumbnail {
  width: 50px;
  height: 50px;
  background-color: #7e1616;
}

//BUTTONS//