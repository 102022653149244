
.navbar{
    width: 100%;
    height: 60px;
    background-color: $mainColor;
}
.linkHeader{
    color: white;
    text-decoration: none;
    background-color: green;
    padding: 20px 30px;

}