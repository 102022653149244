.singleModel{
    background-color: rgb(217, 217, 255);
    height: 150px;
    width: 150px;
    margin: 10px 20px
}

.squadContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
    background-color: red;
    width: 1000px;
}